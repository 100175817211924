import styles from './Header.module.scss';
import logo from '@/src/images/logo.svg';
import { Container } from '@/src/components/Container';
import sendEventToCounters from '@/src/counterEvents';

type TProps = {
  isFinal?: boolean;
  finalAction?: string;
  isSongSelectionScreen?: boolean;
  onMoreButtonClick?: () => void;
};
export const Header = ({
  isSongSelectionScreen,
  isFinal,
  finalAction,
  onMoreButtonClick,
}: TProps) => {
  // const sendAnalityc = (label: string) => {
  //   sendEventToCounters({
  //     action: 'footer',
  //     label,
  //   });
  // };

  return (
    <div className={styles.header}>
      <Container className={styles.header__container}>
        <div className={styles.header__logo}>
          <img src={logo} width="216" height="42" alt="" />
        </div>
        {!isSongSelectionScreen && (
          <div className={styles.header__links}>
            {/* {!isFinal && (
              <a
                href="https://rating.hh.ru/poll/?utm_source=sibur_hh&utm_medium=referral&utm_campaign=special_project"
                className={styles.header__link}
                onClick={() => sendAnalityc('vote')}
                target="_blank"
                rel="noreferrer"
              >
                Голосовать
              </a>
            )} */}
            {isFinal && (
              <button
                className={styles.header__link}
                onClick={() => {
                  sendEventToCounters({
                    action: finalAction,
                    label: 'access',
                  });
                  if (typeof onMoreButtonClick === 'function') {
                    onMoreButtonClick();
                  }
                }}
                type="button"
              >
                Играть ещё
              </button>
            )}
          </div>
        )}
      </Container>
    </div>
  );
};
