import { HOME_SCREEN_TYPE } from '@/src/constants/home';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IState {
  homeScreenType: keyof typeof HOME_SCREEN_TYPE;
}

const initialState: IState = {
  homeScreenType: HOME_SCREEN_TYPE.MAIN,
};

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setHomeScreenType(
      state,
      action: PayloadAction<keyof typeof HOME_SCREEN_TYPE>,
    ) {
      state.homeScreenType = action.payload;
    },
  },
});

export const { setHomeScreenType } = homeSlice.actions;

export default homeSlice;
