import { Container } from '@/src/components/Container';
import { Footer } from './components/Footer';
import { Header } from './components/Header';
import cn from 'classnames';
import styles from './Home.module.scss';
import wave from '@/src/images/wave.gif';
import cardLogoHH from '@/src/images/home-card-logo-HH.png';
import { useCallback, useEffect, useRef } from 'react';
import { GameSelectionCard } from './components/GameSelectionCard/GameSelectionCard';
import { SongCard } from '../Game/components/SongCard';

import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import { useAppSelector, useMediaQuery } from '@/src/hooks';
import { usePageContext } from '@/src/components/App/PageContext';
import { noop } from '@/src/utils/noop';
import { useDispatch } from 'react-redux';
import {
  setGameType,
  setSongSlug,
  shuffleQuestion,
} from '@/src/store/slices/gameSlice';
import { GAME_SELECTION_CARDS, SONGS_LIST } from './data';
import { GAME_TYPE } from '@/src/constants';
import sendEventToCounters from '@/src/counterEvents';
import { VideoBg } from '@/src/components/VideoBg';
import { currentHomeScreenTypeSelector } from '@/src/store/selectors/home';
import { HOME_SCREEN_TYPE } from '@/src/constants/home';
import { setHomeScreenType } from '@/src/store/slices/homeSlice';

export const Home = () => {
  const pageContext = usePageContext();
  const changePage = pageContext?.setPage || noop;

  const dispatch = useDispatch();

  const homeScreenType = useAppSelector(currentHomeScreenTypeSelector);
  const isGameSelectionScreen =
    homeScreenType === HOME_SCREEN_TYPE.GAME_SELECTION;
  const isSongSelectionScreen =
    homeScreenType === HOME_SCREEN_TYPE.SONG_SELECTION;

  const isCardsSlider = useMediaQuery('(max-width: 1080px)');

  const sliderRef = useRef<SwiperRef | null>(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  const handleSelectSingType = (songSlug: string) => {
    changePage('game');
    dispatch(setGameType(GAME_TYPE.SING));
    dispatch(setSongSlug(songSlug));
  };

  useEffect(() => {
    dispatch(shuffleQuestion());
  }, []);

  return (
    <div
      className={cn(styles.home, {
        [styles['home--game-selections']]:
          isGameSelectionScreen || isSongSelectionScreen,
      })}
    >
      <Header isSongSelectionScreen={isSongSelectionScreen} />
      {!isGameSelectionScreen && !isSongSelectionScreen && (
        <VideoBg className={styles.home__videobg} />
      )}
      <div className={styles.home__main}>
        <Container className={styles.home__container}>
          {!isGameSelectionScreen && !isSongSelectionScreen && (
            <>
              <div className={styles['home__main-header']}>
                <div className={styles['home__main-title']}>
                  К команде СИБУРа присоединился <span>великий интеллект</span>
                </div>
              </div>
              <div
                className={cn(styles.home__cards, styles['home__cards--home'])}
              >
                <button
                  className={styles.card}
                  type="button"
                  onClick={() => {
                    dispatch(
                      setHomeScreenType(HOME_SCREEN_TYPE.GAME_SELECTION),
                    );
                    sendEventToCounters({
                      action: 'landing',
                      label: 'access',
                    });
                  }}
                >
                  <div className={styles.card__text}>
                    Угадай, какие мелодии вдохновляют Дмитрия Ивановича?
                  </div>
                  <div className={styles.card__arrow}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                    >
                      <path
                        fill="#fff"
                        d="M18.5 2A1.5 1.5 0 0 0 17 .5H3.5a1.5 1.5 0 1 0 0 3h12v12a1.5 1.5 0 0 0 3 0V2ZM3.06 18.06l15-15L15.94.94l-15 15 2.12 2.12Z"
                      />
                    </svg>
                  </div>

                  <div className={styles.card__button}>Принять вызов!</div>

                  <div className={styles.card__logo}>
                    <img src={wave} width={40} height={40} alt="" />
                  </div>
                </button>

                <a
                  className={cn(styles.card, styles['card--vote'])}
                  target="_blank"
                  // href="https://rating.hh.ru/poll/?utm_source=sibur_hh&utm_medium=referral&utm_campaign=special_project"
                  // href="https://hh.ru/employer/3809"
                  href="https://ulyanovsk.hh.ru/employer/3809"
                  // onClick={() => {
                  //   sendEventToCounters({
                  //     action: 'landing',
                  //     label: 'vote',
                  //   });
                  // }}
                  onClick={() => {
                    sendEventToCounters({
                      action: 'landing',
                      label: 'sibur24_footer_vacancies',
                    });
                  }}
                  rel="noreferrer"
                >
                  <div className={styles.card__text}>
                    {/* Узнай больше о&nbsp;команде СИБУРа и&nbsp;наших проектах! */}
                    {/* Поддержи СИБУР в&nbsp;Рейтинге работодателей России! */}
                    Ищешь цели, достойные тебя? Присоединяйся к&nbsp;команде
                    СИБУРа.
                  </div>
                  <div className={styles.card__arrow}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                    >
                      <path
                        fill="#fff"
                        d="M18.5 2A1.5 1.5 0 0 0 17 .5H3.5a1.5 1.5 0 1 0 0 3h12v12a1.5 1.5 0 0 0 3 0V2ZM3.06 18.06l15-15L15.94.94l-15 15 2.12 2.12Z"
                      />
                    </svg>
                  </div>

                  <div className={styles.card__button}>
                    {/* Узнать больше */}
                    {/* Голосовать */}
                    Вакансии СИБУР
                  </div>

                  <div
                    className={cn(styles.card__logo, styles['card__logo--25'])}
                  >
                    <img src={cardLogoHH} width={25} height={25} alt="" />
                  </div>
                </a>

                <div className={styles['card-mobile']}>
                  <div className={styles['card-mobile__text']}>
                    Угадай, какие мелодии вдохновляют Дмитрия Ивановича?
                  </div>
                  <button
                    className={cn(
                      styles['card-mobile__button'],
                      styles['card-mobile__button--orange'],
                    )}
                    type="button"
                    onClick={() => {
                      dispatch(
                        setHomeScreenType(HOME_SCREEN_TYPE.GAME_SELECTION),
                      );
                      sendEventToCounters({
                        action: 'landing',
                        label: 'access',
                      });
                    }}
                  >
                    <div className={styles['card-mobile__button-logo']}>
                      <img src={wave} width={40} height={40} alt="" />
                    </div>
                    <div className={styles['card-mobile__button-text']}>
                      Принять вызов!
                    </div>
                    <div className={styles['card-mobile__button-arrow']}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                      >
                        <path
                          fill="#fff"
                          d="M18.5 2A1.5 1.5 0 0 0 17 .5H3.5a1.5 1.5 0 1 0 0 3h12v12a1.5 1.5 0 0 0 3 0V2ZM3.06 18.06l15-15L15.94.94l-15 15 2.12 2.12Z"
                        />
                      </svg>
                    </div>
                  </button>
                </div>

                <div
                  className={cn(
                    styles['card-mobile'],
                    styles['card-mobile--vote'],
                  )}
                >
                  <div className={styles['card-mobile__text']}>
                    {/* Узнай больше о&nbsp;команде СИБУРа и&nbsp;наших проектах! */}
                    {/* Поддержи СИБУР в&nbsp;Рейтинге работодателей России! */}
                    Ищешь цели, достойные тебя?
                    <br />
                    Присоединяйся к команде СИБУРа.
                  </div>
                  <a
                    className={styles['card-mobile__button']}
                    // href="https://rating.hh.ru/poll/?utm_source=sibur_hh&utm_medium=referral&utm_campaign=special_project"
                    // onClick={() => {
                    //   sendEventToCounters({
                    //     action: 'landing',
                    //     label: 'vote',
                    //   });
                    // }}
                    target="_blank"
                    rel="noreferrer"
                    href="https://hh.ru/employer/3809"
                    onClick={() => {
                      sendEventToCounters({
                        action: 'landing',
                        label: 'sibur24_footer_vacancies',
                      });
                    }}
                  >
                    <div
                      className={cn(
                        styles['card-mobile__button-logo'],
                        styles['card-mobile__button-logo--25'],
                      )}
                    >
                      <img src={cardLogoHH} width={25} height={25} alt="" />
                    </div>
                    <div className={styles['card-mobile__button-text']}>
                      {/* Голосовать */}
                      Вакансии СИБУР
                    </div>
                    <div className={styles['card-mobile__button-arrow']}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="19"
                        viewBox="0 0 19 19"
                        fill="none"
                      >
                        <path
                          fill="#fff"
                          d="M18.5 2A1.5 1.5 0 0 0 17 .5H3.5a1.5 1.5 0 1 0 0 3h12v12a1.5 1.5 0 0 0 3 0V2ZM3.06 18.06l15-15L15.94.94l-15 15 2.12 2.12Z"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </>
          )}
          {isGameSelectionScreen && !isSongSelectionScreen && (
            <>
              <div
                className={cn(
                  styles['home__main-header'],
                  styles['home__main-header--selections'],
                )}
              >
                <div className={styles['home__main-title']}>Выбери игру:</div>
                {isCardsSlider && (
                  <div className={styles['home__main-nav']}>
                    <button
                      className={cn(
                        styles['home__main-navbutton'],
                        styles['home__main-navbutton--prev'],
                      )}
                      type="button"
                      onClick={handlePrev}
                    >
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g>
                          <rect
                            x="-0.75"
                            y="0.75"
                            width="30.5"
                            height="30.5"
                            rx="9.25"
                            transform="matrix(-1 0 0 1 30.5 0)"
                            stroke="white"
                            strokeWidth="1.5"
                          />
                          <path
                            d="M9.46967 16.5303C9.17678 16.2374 9.17678 15.7626 9.46967 15.4697L14.2426 10.6967C14.5355 10.4038 15.0104 10.4038 15.3033 10.6967C15.5962 10.9896 15.5962 11.4645 15.3033 11.7574L11.0607 16L15.3033 20.2426C15.5962 20.5355 15.5962 21.0104 15.3033 21.3033C15.0104 21.5962 14.5355 21.5962 14.2426 21.3033L9.46967 16.5303ZM22 16.75L10 16.75L10 15.25L22 15.25L22 16.75Z"
                            fill="white"
                          />
                        </g>
                      </svg>
                    </button>
                    <button
                      className={cn(
                        styles['home__main-navbutton'],
                        styles['home__main-navbutton--next'],
                      )}
                      type="button"
                      onClick={handleNext}
                    >
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.75"
                          y="0.75"
                          width="30.5"
                          height="30.5"
                          rx="9.25"
                          stroke="white"
                          strokeWidth="1.5"
                        />
                        <path
                          d="M22.5303 16.5303C22.8232 16.2374 22.8232 15.7626 22.5303 15.4697L17.7574 10.6967C17.4645 10.4038 16.9896 10.4038 16.6967 10.6967C16.4038 10.9896 16.4038 11.4645 16.6967 11.7574L20.9393 16L16.6967 20.2426C16.4038 20.5355 16.4038 21.0104 16.6967 21.3033C16.9896 21.5962 17.4645 21.5962 17.7574 21.3033L22.5303 16.5303ZM10 16.75L22 16.75L22 15.25L10 15.25L10 16.75Z"
                          fill="white"
                        />
                      </svg>
                    </button>
                  </div>
                )}
                <div className={styles['home__main-header-notice']}>
                  <span>Не забудь включить звук!</span>
                </div>
              </div>

              {!isCardsSlider && (
                <div className={styles.home__cards}>
                  {GAME_SELECTION_CARDS.map((card) => (
                    <GameSelectionCard
                      key={card.id}
                      onClick={() => {
                        if (card.type === GAME_TYPE.SING) {
                          dispatch(
                            setHomeScreenType(HOME_SCREEN_TYPE.SONG_SELECTION),
                          );
                          return;
                        }

                        changePage('game');
                        dispatch(setGameType(card.type));
                      }}
                      className={styles['home__game-selection-card']}
                      {...card}
                    />
                  ))}
                </div>
              )}

              {isCardsSlider && (
                <Swiper
                  className={styles.home__swiper}
                  modules={[Navigation]}
                  slidesPerView={'auto'}
                  spaceBetween={8}
                  slidesOffsetAfter={16}
                  slidesOffsetBefore={16}
                  ref={sliderRef}
                  breakpoints={{
                    375: {
                      slidesOffsetAfter: 32,
                      slidesOffsetBefore: 32,
                    },
                  }}
                >
                  {GAME_SELECTION_CARDS.map((card) => (
                    <SwiperSlide className={styles.home__slide} key={card.id}>
                      <GameSelectionCard
                        onClick={() => {
                          if (card.type === GAME_TYPE.SING) {
                            dispatch(
                              setHomeScreenType(
                                HOME_SCREEN_TYPE.SONG_SELECTION,
                              ),
                            );
                            return;
                          }

                          changePage('game');
                          dispatch(setGameType(card.type));
                        }}
                        className={styles['home__game-selection-card']}
                        {...card}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </>
          )}

          {isSongSelectionScreen && (
            <>
              <div
                className={cn(
                  styles['home__main-header'],
                  styles['home__main-header--selections'],
                )}
              >
                <div className={styles['home__main-title']}>Выбери песню:</div>
                <button
                  className={styles['home__main-back']}
                  type="button"
                  onClick={() => {
                    dispatch(
                      setHomeScreenType(HOME_SCREEN_TYPE.GAME_SELECTION),
                    );
                    dispatch(setGameType(null));
                  }}
                >
                  Назад
                </button>
              </div>

              <div className={styles.home__songs}>
                {SONGS_LIST.map((song) => (
                  <SongCard
                    key={song.id}
                    className={styles['home__song-card']}
                    song={song}
                    onClick={(songSlug: string) => {
                      handleSelectSingType(songSlug);

                      sendEventToCounters({
                        action: 'sing',
                        label: song.analitycLabel,
                      });
                    }}
                  />
                ))}
              </div>
            </>
          )}
        </Container>
      </div>

      <Footer isSongSelectionScreen={isSongSelectionScreen} />
    </div>
  );
};
