import { QUESTIONS, TQuestion } from '@/src/pages/Game/data';
import { GAME_TYPE } from '@/src/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { shuffle } from '@/src/utils/shuffle';

interface IState {
  gameType: keyof typeof GAME_TYPE | null;
  numberCorrectAnswers: number;
  songSlug?: string;
  currentQuestion: number;
  questions: TQuestion[];
}

const initialState: IState = {
  gameType: null,
  numberCorrectAnswers: 0,
  currentQuestion: 0,
  questions: QUESTIONS,
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setGameType(state, action: PayloadAction<keyof typeof GAME_TYPE | null>) {
      state.gameType = action.payload;
    },
    setNumberCorrectAnswers(state, action: PayloadAction<number>) {
      state.numberCorrectAnswers += action.payload;
    },
    resetNumberCorrectAnswers(state) {
      state.numberCorrectAnswers = 0;
    },
    setSongSlug(state, action: PayloadAction<string>) {
      state.songSlug = action.payload;
    },
    addCurrentQuestion(state) {
      state.currentQuestion += 1;
    },
    resetCurrentQuestion(state) {
      state.currentQuestion = 0;
    },
    shuffleQuestion(state) {
      state.questions = shuffle(state.questions);
    },
  },
});

export const {
  setGameType,
  setNumberCorrectAnswers,
  resetNumberCorrectAnswers,
  setSongSlug,
  addCurrentQuestion,
  resetCurrentQuestion,
  shuffleQuestion,
} = gameSlice.actions;

export default gameSlice;
