import styles from './Game.module.scss';
import { Preloader } from '@/src/components/Preloader';
import { GameTimer } from './components/GameTimer/GameTimer';
import { Container } from '@/src/components/Container';
import { GameProgress } from './components/GameProgress';
import { Question } from './components/Question';
import cn from 'classnames';
import ReactPlayer from 'react-player';

export type TQuestionResult = {
  id: number;
  isCorrectly: boolean;
};

import { useEffect, useMemo, useRef, useState } from 'react';
import { SongText } from './components/SongText';
import { usePageContext } from '@/src/components/App/PageContext';
import { noop } from '@/src/utils/noop';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/src/hooks';
import {
  currentGameTypeSelector,
  currentQuestionSelector,
  currentShuffledQuestionSelector,
  currentSongSlugSelector,
} from '@/src/store/selectors/game';
import {
  addCurrentQuestion,
  resetCurrentQuestion,
  setNumberCorrectAnswers,
} from '@/src/store/slices/gameSlice';
import { SONGS, TAnswer, TQuestion } from './data';
import {
  DELAY_BEFORE_MOVING_ON_TO_THE_NEXT_QUESTION,
  GAME_TYPE,
  KARAOKE_COUNTDOWN,
  TRACK_PERCENTAGE_START_OF_SONG_COMPLETION,
} from '@/src/constants';
import {
  resetLineIndex,
  resetProgress,
  setLoaded,
  setLoadedSeconds,
  setPlayed,
  setPlayedSeconds,
} from '@/src/store/slices/progressSlice';
import { OnProgressProps } from 'react-player/base';
import { isMobile } from 'react-device-detect';
import { currentProgressPlayedSecondsSelector } from '@/src/store/selectors/progress';
import sendEventToCounters from '@/src/counterEvents';
import barrier from '@/src/images/barrier.svg';
import { SongTimer } from './components/SongTimer';

export const Game = () => {
  const pageContext = usePageContext();
  const changePage = pageContext?.setPage || noop;

  const dispatch = useDispatch();
  const gameType = useAppSelector(currentGameTypeSelector);
  const songSlug = useAppSelector(currentSongSlugSelector);
  const shuffledQuestions = useAppSelector(currentShuffledQuestionSelector);
  const isGameTypeIsSing = gameType === GAME_TYPE.SING;
  const isGameTypeIsGuessWord = gameType === GAME_TYPE.GUESS_WORD;

  const [gameTime, setGameTime] = useState(60);
  const [currentSongDuration, setCurrentSongDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState<TAnswer | null>(null);

  const currentQuestion = isGameTypeIsSing
    ? shuffledQuestions.findIndex((question) => question.id === songSlug)
    : useAppSelector(currentQuestionSelector);

  const question: TQuestion = useMemo(
    () =>
      isGameTypeIsSing
        ? SONGS[SONGS.findIndex((question) => question.id === songSlug)]
        : shuffledQuestions[currentQuestion],
    [currentQuestion],
  );

  const [isStartGame, setIsStartGame] = useState(isGameTypeIsSing);

  const timerGameId = useRef<ReturnType<typeof setTimeout> | null>(null);
  const timerKaraokeId = useRef<ReturnType<typeof setTimeout> | null>(null);

  const player = useRef<ReactPlayer | null>(null);

  const handleGameTimeChange = (n: number) => {
    if (gameTime === 0) {
      changePage('final');
      return;
    }
    setGameTime(n);
  };

  let action = '';

  switch (true) {
    case gameType == GAME_TYPE.GUESS_SONG:
      action = 'melody';
      break;
    case gameType == GAME_TYPE.GUESS_WORD:
      action = 'word';
      break;
    case gameType == GAME_TYPE.SING:
      action = 'sing';
      break;
  }

  const setNextQuestion = () => {
    if (currentQuestion === shuffledQuestions.length - 1) {
      sendEventToCounters({
        action,
        label: gameType == GAME_TYPE.GUESS_SONG ? 'finel' : 'final',
      });

      changePage('final');
      dispatch(resetCurrentQuestion());
    } else {
      dispatch(addCurrentQuestion());
      dispatch(resetProgress());
    }
  };
  const handleSingTypeGameEnd = () => {
    sendEventToCounters({
      action: 'sing',
      label: 'final',
    });

    changePage('final');
    dispatch(resetLineIndex());
  };

  const handleAnswerButtonClick = (answer: TAnswer) => {
    setSelectedAnswer(answer);

    if (!isGameTypeIsGuessWord) {
      setTimeout(() => {
        dispatch(setNumberCorrectAnswers(answer?.isTrue ? 1 : 0));
        setSelectedAnswer(null);
        setNextQuestion();
      }, DELAY_BEFORE_MOVING_ON_TO_THE_NEXT_QUESTION);
    }
  };

  const handleNextButtonClick = () => {
    if (isGameTypeIsSing) {
      handleSingTypeGameEnd();
      return;
    }

    dispatch(setNumberCorrectAnswers(selectedAnswer?.isTrue ? 1 : 0));
    setSelectedAnswer(null);
    setNextQuestion();
  };

  const startGame = () => {
    setIsStartGame(true);

    sendEventToCounters({
      action,
      label: 'start',
    });
  };

  const handleProgress = ({
    playedSeconds,
    played,
    loadedSeconds,
    loaded,
  }: OnProgressProps) => {
    dispatch(setPlayedSeconds(playedSeconds));
    dispatch(setPlayed(played));
    dispatch(setLoadedSeconds(loadedSeconds));
    dispatch(setLoaded(loaded));

    const progressInPercent = (playedSeconds * 100) / currentSongDuration;

    if (progressInPercent >= TRACK_PERCENTAGE_START_OF_SONG_COMPLETION) {
      const vol =
        ((100 - progressInPercent) * 100) /
        (100 - TRACK_PERCENTAGE_START_OF_SONG_COMPLETION) /
        100;
      setVolume(vol > 0 ? vol : 0);
    } else {
      setVolume(1);
    }

    if (playedSeconds < 1) {
      dispatch(resetLineIndex());
    }
  };

  const progressPlayedSeconds = useAppSelector(
    currentProgressPlayedSecondsSelector,
  );

  useEffect(() => {
    if (isGameTypeIsSing) {
      startGame();
    }
  }, []);

  return (
    <div
      className={cn(styles.game, {
        [styles['game--sign']]: isGameTypeIsSing,
      })}
    >
      <div className={styles.wrapper}>
        {!isStartGame ? (
          <Preloader onPreloadEnded={startGame} />
        ) : (
          <>
            <div className={styles.game__header}>
              <Container className={styles.game__flex}>
                {!isGameTypeIsGuessWord && !isGameTypeIsSing && (
                  <GameTimer
                    onTimerEnded={() => {}}
                    timerId={timerGameId.current}
                    onCountdownChange={handleGameTimeChange}
                    countdown={gameTime}
                  />
                )}
                {(isGameTypeIsGuessWord || isGameTypeIsSing) && (
                  <button
                    className={cn(styles.game__next, {
                      [styles['game__next--right']]: isGameTypeIsSing,
                    })}
                    type="button"
                    onClick={() => {
                      handleNextButtonClick();
                    }}
                  >
                    Далее
                  </button>
                )}
                {!isGameTypeIsSing && (
                  <GameProgress
                    currentCount={currentQuestion + 1}
                    totalCount={shuffledQuestions.length}
                    className={styles.game__progress}
                  />
                )}
              </Container>
            </div>

            <div
              className={cn(styles.game__main, {
                ['sing-game']: isGameTypeIsSing,
              })}
            >
              <Container>
                <div className={styles.game__video}>
                  <ReactPlayer
                    url={[question.song.mp4, question.song.webm]}
                    loop={!isGameTypeIsSing}
                    playing={isPlaying}
                    preload={isMobile ? 'metadata' : 'none'}
                    progressInterval={100}
                    onProgress={handleProgress}
                    ref={player}
                    muted={
                      (isGameTypeIsGuessWord &&
                        progressPlayedSeconds >= question.mutedStart &&
                        progressPlayedSeconds < question.mutedEnd) ||
                      false
                    }
                    volume={volume}
                    onDuration={(dur: number) => setCurrentSongDuration(dur)}
                    playsinline={true}
                    onEnded={() => {
                      if (isGameTypeIsSing) {
                        handleSingTypeGameEnd();
                        return;
                      }
                    }}
                    onReady={() => {
                      if (
                        isGameTypeIsSing &&
                        question.wordsStart < KARAOKE_COUNTDOWN
                      ) {
                        setTimeout(
                          () => {
                            setIsPlaying(true);
                          },
                          (KARAOKE_COUNTDOWN - question.wordsStart) * 1000,
                        );
                        return;
                      }
                      setIsPlaying(true);
                    }}
                    config={{
                      file: { attributes: { poster: question.poster } },
                    }}
                  />

                  {isGameTypeIsGuessWord &&
                    progressPlayedSeconds >= question.mutedStart &&
                    progressPlayedSeconds < question.mutedEnd && (
                      <div
                        className={cn(
                          styles['game__video-barrier'],
                          styles[`game__video-barrier--${question.id}`],
                        )}
                      >
                        <img src={barrier} alt="" />
                      </div>
                    )}
                  {isGameTypeIsSing &&
                    progressPlayedSeconds >= question.wordsStart - 3.5 &&
                    progressPlayedSeconds < question.wordsStart && (
                      <SongTimer
                        className={styles['game__song-timer']}
                        seconds={Math.round(
                          question.wordsStart - progressPlayedSeconds,
                        )}
                        timerId={timerKaraokeId.current}
                        isPlaybackPaused={
                          question.wordsStart < KARAOKE_COUNTDOWN
                        }
                      />
                    )}
                </div>
                {!isGameTypeIsSing ? (
                  <Question
                    onAnswerButtonClick={handleAnswerButtonClick}
                    className={styles.game__question}
                    isGuessWordGameType={isGameTypeIsGuessWord}
                    selectedAnswer={selectedAnswer}
                  />
                ) : question.songTextLines.length ? (
                  <SongText
                    className={styles.question__songtext}
                    textProps={question.songTextLines}
                  />
                ) : null}
              </Container>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
