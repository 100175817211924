import { RootState } from '..';

export const currentGameTypeSelector = (state: RootState) =>
  state.game.gameType;

export const currentNumberCorrectAnswersSelector = (state: RootState) =>
  state.game.numberCorrectAnswers;

export const currentSongSlugSelector = (state: RootState) =>
  state.game.songSlug;

export const currentQuestionSelector = (state: RootState) =>
  state.game.currentQuestion;

export const currentShuffledQuestionSelector = (state: RootState) =>
  state.game.questions;
