// import { useState } from 'react';
import { AnswerButton } from './components/AnswerButton';
import { Progress } from './components/Progress';
import styles from './Question.module.scss';
import cn from 'classnames';
import { SongText } from '../SongText';
import { TAnswer } from '../../data';
import { useAppSelector } from '@/src/hooks';
import { currentProgressPlayedSelector } from '@/src/store/selectors/progress';
import {
  currentQuestionSelector,
  currentShuffledQuestionSelector,
} from '@/src/store/selectors/game';
// import { DELAY_BEFORE_MOVING_ON_TO_THE_NEXT_QUESTION } from '@/src/constants';

type TProps = {
  className?: string;
  onAnswerButtonClick: (answer: TAnswer) => void;
  isGuessWordGameType: boolean;
  selectedAnswer: TAnswer | null;
};

export const Question = ({
  className,
  onAnswerButtonClick,
  isGuessWordGameType,
  selectedAnswer,
}: TProps) => {
  const progressPlayed = useAppSelector(currentProgressPlayedSelector);
  // const [selectedAnswer, setSelectedAnswer] = useState<TAnswer | null>(null);

  const currentQuestion = useAppSelector(currentQuestionSelector);

  const shuffledQuestions = useAppSelector(currentShuffledQuestionSelector);
  const question = shuffledQuestions[currentQuestion];

  const { songTextLines, answers, words } = question;

  // const handleAnswerButtonClick = (answer: TAnswer) => {
  //   // setSelectedAnswer(answer);

  //   setTimeout(() => {
  //     onAnswerButtonClick(answer?.isTrue ? 1 : 0);
  //     // setSelectedAnswer(null);
  //   }, DELAY_BEFORE_MOVING_ON_TO_THE_NEXT_QUESTION);
  // };

  return (
    <div
      className={cn(styles.question, {
        [`${className}`]: className !== undefined,
      })}
    >
      <Progress
        className={styles.question__progress}
        progress={progressPlayed * 100}
      />

      {songTextLines.length ? (
        <SongText
          className={styles.question__songtext}
          isGuessWordGameType={isGuessWordGameType}
        />
      ) : null}

      <div
        className={cn(styles.question__answers, {
          ['disabled']: selectedAnswer !== null,
        })}
      >
        {!isGuessWordGameType &&
          answers.map((answer) => (
            <AnswerButton
              key={answer.text}
              className={styles.question__answer}
              answer={answer}
              onClick={onAnswerButtonClick}
              isSelected={selectedAnswer?.id === answer.id}
            />
          ))}
        {isGuessWordGameType &&
          words.map((word) => (
            <AnswerButton
              key={word.text}
              className={styles.question__answer}
              answer={word}
              onClick={onAnswerButtonClick}
              isSelected={selectedAnswer?.id === word.id}
            />
          ))}
      </div>
    </div>
  );
};
