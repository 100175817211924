import { GAME_TYPE } from '@/src/constants';
import styles from './GameSelectionCard.module.scss';
import cn from 'classnames';
import sendEventToCounters from '@/src/counterEvents';

type TProps = {
  title: string;
  description: string;
  image: string;
  items: string[];
  buttonText?: string;
  className?: string;
  type: string;
  analitycLabel: string;
  onClick: () => void;
};

export const GameSelectionCard = ({
  title,
  image,
  items,
  buttonText,
  className,
  description,
  type,
  analitycLabel,
  onClick,
}: TProps) => {
  return (
    <div
      className={cn(styles.card, {
        [`${className}`]: className !== undefined,
        [styles.GuessWord]: type === GAME_TYPE.GUESS_WORD,
        [styles.GuessSong]: type === GAME_TYPE.GUESS_SONG,
        [styles.Sing]: type === GAME_TYPE.SING,
      })}
    >
      <div className={styles.card__header}>
        <div className={styles['card__header-text']}>{title}</div>
        <div className={styles['card__header-icon']}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
          >
            <path
              //   fill="#fff"
              d="M18.5 2A1.5 1.5 0 0 0 17 .5H3.5a1.5 1.5 0 1 0 0 3h12v12a1.5 1.5 0 0 0 3 0V2ZM3.06 18.06l15-15L15.94.94l-15 15 2.12 2.12Z"
            />
          </svg>
        </div>
      </div>
      <div className={styles.card__description}>{description}</div>
      <div className={styles.card__list}>
        {items.map((item, index) => (
          <div key={item + index} className={styles['card__list-item']}>
            <span>{item}</span>
          </div>
        ))}
      </div>
      <div className={styles.card__bottom}>
        <button
          className={styles['card__bottom-button']}
          type="button"
          onClick={() => {
            onClick();
            sendEventToCounters({
              action: 'choiceGame',
              label: analitycLabel,
            });
          }}
        >
          {buttonText !== undefined ? buttonText : 'Играть'}
        </button>
        <div
          className={cn(styles['card__bottom-icon'], {
            [styles['card__bottom-icon--bordered']]: type === GAME_TYPE.SING,
            [styles['card__bottom-icon--no-pad']]:
              type === GAME_TYPE.SING || type === GAME_TYPE.GUESS_WORD,
          })}
        >
          <img src={image} width={40} height={40} alt="" />
        </div>
      </div>
    </div>
  );
};
