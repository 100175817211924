import styles from './Footer.module.scss';
import logo from '@/src/images/hh-logo.png';
import { Container } from '@/src/components/Container';
import cn from 'classnames';
import sendEventToCounters from '@/src/counterEvents';

type TProps = {
  isSongSelectionScreen?: boolean;
};

export const Footer = ({ isSongSelectionScreen }: TProps) => {
  const sendAnalityc = (label: string) => {
    sendEventToCounters({
      action: 'footer',
      label,
    });
  };

  return (
    <div className={styles.footer}>
      <Container className={styles.footer__container}>
        <div className={styles.footer__copyright}>
          © СИБУР 2024&nbsp;&nbsp;|
        </div>
        <div className={styles.footer__brand}>
          <div className={styles['footer__brand-logo']}>
            <img src={logo} width={18} height={18} alt="" />
          </div>
          <div className={styles['footer__brand-text']}>Бренд-центр</div>
        </div>

        {!isSongSelectionScreen && (
          <a
            href="https://hh.ru/employer/3809?utm_source=sibur_hh&utm_medium=referral&utm_campaign=special_project_vacancy"
            target="_blank"
            className={cn(styles.footer__link)}
            onClick={() => sendAnalityc('vacancies')}
            rel="noreferrer"
          >
            Вакансии
          </a>
        )}
        <div className={styles.footer__text}>
          <a href={'/copyright.pdf'} target="_blank" rel="noreferrer">
            ©, ® по лицензии ООО «Первое музыкальное издательство»
          </a>
        </div>
      </Container>
    </div>
  );
};
