import styles from './VideoBg.module.scss';
import cn from 'classnames';

import videoMP4 from '@/src/videos/video-bg.mp4';
import videoWEBM from '@/src/videos/video-bg.webm';
import poster from '@/src/images/videoBgPoster.jpg';
import ReactPlayer from 'react-player';

import { isMobile } from 'react-device-detect';

type TProps = {
  className?: string;
};

export const VideoBg = ({ className }: TProps) => {
  return (
    <div
      className={cn(styles.root, {
        [`${className}`]: className !== undefined,
      })}
    >
      <ReactPlayer
        url={[videoMP4, videoWEBM]}
        loop={true}
        playing={true}
        preload={isMobile ? 'metadata' : 'none'}
        muted={true}
        playsinline={true}
        config={{
          file: { attributes: { poster } },
        }}
      />

      <div className={styles.root__helper}></div>
    </div>
  );
};
