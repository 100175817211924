import { configureStore } from '@reduxjs/toolkit';
import homeSlice from './slices/homeSlice';
import gameSlice from './slices/gameSlice';
import progressSlice from './slices/progressSlice';

export const store = configureStore({
  reducer: {
    home: homeSlice.reducer,
    game: gameSlice.reducer,
    progress: progressSlice.reducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
