import React, { useEffect, useMemo } from 'react';
import styles from './SongText.module.scss';
import cn from 'classnames';
import { TSongTextLine } from '../../data';
import { useAppSelector } from '@/src/hooks';
import {
  currentLineIndexSelector,
  currentProgressPlayedSecondsSelector,
} from '@/src/store/selectors/progress';
import { nanoid } from 'nanoid';
import { useDispatch } from 'react-redux';
import { addLineIndex } from '@/src/store/slices/progressSlice';
import {
  currentQuestionSelector,
  currentShuffledQuestionSelector,
} from '@/src/store/selectors/game';

type TProps = {
  textProps?: TSongTextLine[];
  className?: string;
  isGuessWordGameType?: boolean;
};

const getPercent = (start: number, end: number, seconds: number) => {
  if (seconds < start) {
    return 0;
  }
  if (seconds >= end) {
    return 100;
  }
  return (seconds - start) * (100 / (end - start));
};

export const SongText = ({
  textProps,
  className,
  isGuessWordGameType,
}: TProps) => {
  const progressPlayedSeconds = useAppSelector(
    currentProgressPlayedSecondsSelector,
  );
  const currentIndex = useAppSelector(currentLineIndexSelector);

  const dispatch = useDispatch();

  const currentQuestion = useAppSelector(currentQuestionSelector);
  const shuffledQuestions = useAppSelector(currentShuffledQuestionSelector);
  const question = useMemo(
    () => shuffledQuestions[currentQuestion],
    [currentQuestion],
  );

  const text = textProps || question.songTextLines;

  const nextLineTime = useMemo(
    () => text[currentIndex].endLineTime,
    [text, currentIndex],
  );

  const line = text[currentIndex];

  useEffect(() => {
    if (
      progressPlayedSeconds > nextLineTime &&
      currentIndex < text.length - 1
    ) {
      dispatch(addLineIndex());
    }
  }, [progressPlayedSeconds]);

  return (
    <div
      className={cn(styles.songText, {
        [`${className}`]: className !== undefined,
      })}
    >
      {line.parts.map(({ text, start, end, isHidden, isNoSpace }) => (
        <React.Fragment key={nanoid()}>
          <span className={styles.songText__part}>
            <span
              className={cn(styles['songText__part-text'], {
                [styles['songText__part-text--hidden']]:
                  isGuessWordGameType && isHidden,
              })}
            >
              {text}
            </span>
            <span
              className={cn(styles['songText__part-progress'], {
                [styles['songText__part-progress--hidden']]:
                  isGuessWordGameType && isHidden,
              })}
              style={{
                backgroundImage: `linear-gradient(to right, #77E2C3 ${getPercent(
                  start,
                  end,
                  progressPlayedSeconds,
                )}%, transparent ${getPercent(
                  start,
                  end,
                  progressPlayedSeconds,
                )}%)`,
              }}
            >
              {text}
            </span>
          </span>
          {isNoSpace === undefined && (
            <span className={styles['songText__whitespace']}>&#160;</span>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};
