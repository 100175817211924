import { useEffect, useState } from 'react';
import styles from './SongTimer.module.scss';
import cn from 'classnames';
import { KARAOKE_COUNTDOWN } from '@/src/constants/game';

type TProps = {
  seconds: number;
  className?: string;
  timerId: ReturnType<typeof setTimeout> | null;
  isPlaybackPaused: boolean;
};

export const SongTimer = ({
  seconds,
  className,
  timerId,
  isPlaybackPaused,
}: TProps) => {
  const [currentNumber, setCurrentNumber] = useState(KARAOKE_COUNTDOWN);

  useEffect(() => {
    const timer = () => {
      setCurrentNumber((prev) => prev - 1);
    };

    timerId = setInterval(timer, 1000);

    return () => {
      if (timerId) {
        clearInterval(timerId);
      }
    };
  }, [currentNumber]);

  return (
    <div
      className={cn(styles.root, {
        [`${className}`]: className !== undefined,
      })}
    >
      {!isPlaybackPaused && (seconds === 0 ? '' : seconds)}
      {isPlaybackPaused && (currentNumber === 0 ? '' : currentNumber)}
    </div>
  );
};
