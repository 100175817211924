import { GAME_TYPE } from '@/src/constants';
import wave from '@/src/images/wave.gif';
import chars from '@/src/images/chars.gif';
import sound from '@/src/images/sound.gif';

export const GAME_SELECTION_CARDS = [
  {
    id: 1,
    title: 'Угадай мелодию',
    description:
      'Дмитрий Иванович сочиняет и поёт песни про СИБУР на мотив известных мелодий. Сколько из них ты сможешь угадать?',
    image: wave,
    items: [
      'Слушай песни',
      'Выбирай название оригинала из 3‑х предложенных вариантов',
      'Думай быстро: время на прослушивание и ответ ограничено',
    ],
    type: GAME_TYPE.GUESS_SONG,
    analitycLabel: 'melody',
  },
  {
    id: 2,
    title: 'Угадай слово',
    description:
      'Менделеев любит сочинять песни о коллегах и о работе в СИБУРе, но иногда забывает слова',
    image: chars,
    items: [
      'Слушай песни',
      'Угадай, какие слова в тексте пропущены: выбери из 3‑х вариантов наиболее подходящее слово',
      'Думай быстро: время на прослушивание и ответ ограничено',
    ],
    type: GAME_TYPE.GUESS_WORD,
    analitycLabel: 'word',
  },
  {
    id: 3,
    title: 'Спой с Иванычем',
    description:
      'Если бы во времена Дмитрия Ивановича было караоке – он бы точно стал его фанатом!',
    image: sound,
    items: [
      'Представь, что ты в караоке вместе с Менделеевым',
      'Выбери песню из списка',
      'Пой с Иванычем!',
    ],
    buttonText: 'Спеть',
    type: GAME_TYPE.SING,
    analitycLabel: 'sing',
  },
];

export const SONGS_LIST = [
  {
    id: 'chelse',
    artist: 'Челси – ',
    title: 'Самая любимая',
    analitycLabel: 'chelsi',
  },
  {
    id: 'iowa',
    artist: 'IOWA – ',
    title: 'Улыбайся',
    analitycLabel: 'iova',
  },
  {
    id: 'tea-together',
    artist: 'Чай вдвоём – ',
    title: 'Ласковая моя',
    analitycLabel: 'teaTwice',
  },
  {
    id: 'dancing-minus',
    artist: 'Танцы Минус – ',
    title: 'Половинка',
    analitycLabel: 'minusDance',
  },
  {
    id: 'zivert',
    artist: 'Zivert – ',
    title: 'Life',
    analitycLabel: 'zivert',
  },
  {
    id: 'kraimbrery',
    artist: 'Мари Краймбрери –',
    title: 'Мне так хорошо',
    analitycLabel: 'mary',
  },
];
